<template>
  <div>
    <h1>Manage Your Pool</h1>
  </div>
  <form @submit.prevent="handleSubmit()">
    <input required v-model="poolId" placeholder="Pool Id" />

    <input
      type="password"
      required
      v-model="poolPassword"
      placeholder="Pool Password"
    />
    <input
      type="password"
      required
      v-model="managerPassword"
      placeholder="Manager Password"
    />

    <div class="submit">
      <button>Manage Pool</button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      poolId: '',
      poolPassword: '',
      managerPassword: ''
    };
  },
  methods: {
    handleSubmit() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: this.poolId,
          poolPassword: this.poolPassword,
          managerPassword: this.managerPassword
        })
      };
      fetch(
        `${process.env.VUE_APP_BASE_URL}/pools/manage`,
        requestOptions
      ).then(async (response) => {
        const data = await response.json();
        // console.log(data);
        if (response.status !== 200) {
          this.$toast.error(data.message, {
            position: 'top',
            duration: 5000
          });
        } else {
          this.$router.push({name: 'ManagePool', params: {id: data.poolId}})
        }
      });
    }
  }
};
</script>