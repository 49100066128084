<template>
  <div>
       <span></span>
  </div>
  <div v-if="poolusers">
    <PoolUsers @close="togglePoolUsers()" />
  </div>
  <div class="pool">
      <div>
        <span class="line1 name">{{ poolName }}</span>
        <span class="line1 date">{{ poolDate }}</span>
        <button class="line1" v-bind:class="{inactive: poolInactive}" :disabled="poolInactive" v-if="poolManager" @click="managePool()">
            Manage Pool
        </button>
        <button class="line1" v-bind:class="{inactive: poolInactive}" :disabled="poolInactive" v-else @click="viewPool()">
            View Pool
        </button>
      </div>
    <div class="line2">
      <span class="cost">${{ poolCost }}/Box</span>
      <button @click="viewUsers()">
        <img src="../assets/icons8-staff-48.png" alt="pool manager">
      </button>
    </div>
  </div>
</template>

<script>
import PoolUsers from '../components/PoolUsers';
export default {
    components: {PoolUsers},
    props: ['poolName', 'poolDate', 'poolCost', 'poolManager', 'poolInactive'],
    data() {
      return {
        poolusers: false
      }
    },
    methods: {
        viewPool(){
            console.log('manage pool');
        },
        managePool() {
            console.log('view pool');
        },
        viewUsers() {
          console.log('view users')
          this.poolusers = !this.poolusers;
        },
        togglePoolUsers() {
          this.poolusers = !this.poolusers;
        }
    }
};
</script>

<style scoped>
.pool {
  margin: 5px;
  background: white;
  border-radius: 30px;
  font-style: normal;
  border: 2px solid #b5b7b9;
}
span {
  margin: 5px;
}
.line1{
  display: inline-block;
  width: 30%;
}
.line2{
  display: inline-block;
  width: 100px;
  margin-bottom: 5px;
}
button {
 padding: 5px 5px;
 margin-top: 5px;
 border: none;
 border-radius: 50px;
}
.cost {
    font-weight: bold;
    color: orangered;
}
.date {
    font-weight: bold;
}
.name {
    font-weight: bold;
}
.inactive {
    background-color: gray;
}
</style>
