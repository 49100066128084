import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Create from '../views/CreatePool.vue';
import View from '../views/View.vue';
import Manage from '../views/Manage.vue';
import Pool from '../views/Pool.vue';
import NotFound from '../views/NotFound.vue';
import ManagePool from '../views/ManagePool.vue';
import Login from '../views/Login.vue';
import UserPools from '../views/UserPools.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import FAQ from '../views/FAQ.vue';
import SignUp from '../views/Signup.vue';
import Landing from '../views/Landing.vue';

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'Home',
    component: Landing
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/create',
    name: 'Create',
    component: Create
  },
  {
    path: '/view',
    name: 'View',
    component: View
  },
  {
    path: '/manage',
    name: 'Manage',
    component: Manage
  },
  // {
  //   path: '/pool',
  //   name: 'Pool',
  //   component: Pool
  // },
  {
    path: '/pool/:id',
    name: 'Pool',
    component: Pool
  },
  {
    path: '/pool/manage/:id',
    name: 'ManagePool',
    component: ManagePool
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: ForgotPassword
  },
  {
    path: '/reset',
    name: 'Reset',
    component: ResetPassword
  },
  {
    path: '/userpools',
    name: 'UserPools',
    component: UserPools
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
