<template>
  <div class="backdrop" @click.self="closeBox()">
    <div class="box">
      <div class="close-btn">
        <button @click="closeBox()">X</button>
      </div>
      <div>
      <table>
        <tr>
          <th>Box</th>
          <th>Firstname</th>
          <th>Lastname</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Paid</th>
        </tr>
        <tr>
          <td>A3</td>
          <td>Jill</td>
          <td>Smith</td>
          <td>50</td>
          <td>1234567892</td>
          <td>true</td>
        </tr>
        <tr>
          <td>A4</td>
          <td>Eve</td>
          <td>Jackson</td>
          <td>94</td>
          <td>1288567892</td>
          <td>false</td>
        </tr>
      </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PoolUsers',
  methods: {
    closeBox() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.box {
  width: 400px auto;
  padding: 20px;
  margin: 100px auto;
  background: white;
  border-radius: 10px;
}
.backdrop {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
h1 {
  color: #61c1ee;
  border: none;
  padding: 0;
}
.box p {
  font-style: normal;
}
.close-btn {
  position: absolute;
  right: 50px;
}
table,
th,
td {
   /* width:100%; */
  border: 2px solid black;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
}
th,
td {
  padding: 15px;
  font-weight: bold;
}
</style>
