<template>
   <img @click="$router.push({name: 'Home'})" alt="Vue logo" src="./assets/logo.png" />
   <p>Online Box Pool Management - BETA</p>
   <router-view />
   <Footer/>
</template>

<script>
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Footer
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


img {
    margin-top: 5px;
    max-width:100%;
    height:auto;
}
</style>
