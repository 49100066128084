<template>
  <!-- <div>
    <h1>Pool Example</h1>
  </div> -->
  <div v-if="showBox">
    <Box
      :box="box"
      :firstName="modalFirstName"
      :lastName="modalLastName"
      :email="modalEmail"
      :phone="modalPhone"
      :paid="modalPaid"
      @close="toggleBoxDefault()"
    />
  </div>
  <div v-if="showReserveBox">
    <ReserveBox :poolId="poolId" :box="box" @close="toggleReserveBoxDefault()" />
  </div>
  <table style="width:100%">
    <caption class="caption">
      {{
        poolData.poolName
      }} - ${{
        poolData.cost
      }}/Box
    </caption>
    <caption class="caption">
      {{
        poolData.homeTeam
      }}
    </caption>
    <thead>
       
    </thead>
    <tbody>
     
    <tr>
      <th></th>
      <th></th>
      <th></th>
      <th class="key">{{ K0 }}</th>
      <th class="key">{{ K1 }}</th>
      <th class="key">{{ K2 }}</th>
      <th class="key">{{ K3 }}</th>
      <th class="key">{{ K4 }}</th>
      <th class="key">{{ K5 }}</th>
      <th class="key">{{ K6 }}</th>
      <th class="key">{{ K7 }}</th>
      <th class="key" >{{ K8 }}</th>
      <th class="key">{{ K9 }}</th>
    </tr>
    <tr>
      <td></td>
      <th class="leftcaption" rowspan="11">{{poolData.awayTeam}}</th>
      <td class="key keyright">{{ L0 }}</td>
      <td>
        <button v-if="A0" @click="toggleReserveBox('A0')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else @click="toggleBox('A0')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="A1" :box="box" @click="toggleReserveBox('A1')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('A1')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="A2" :box="box" @click="toggleReserveBox('A2')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('A2')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="A3" :box="box" @click="toggleReserveBox('A3')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('A3')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="A4" :box="box" @click="toggleReserveBox('A4')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('A4')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="A5" :box="box" @click="toggleReserveBox('A5')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('A5')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="A6" :box="box" @click="toggleReserveBox('A6')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('A6')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="A7" :box="box" @click="toggleReserveBox('A7')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('A7')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="A8" :box="box" @click="toggleReserveBox('A8')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('A8')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="A9" :box="box" @click="toggleReserveBox('A9')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('A9')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
    </tr>
    <tr>
      <td></td>
      <td class="key keyright">{{ L1 }}</td>
      <td>
        <button v-if="B0" @click="toggleReserveBox('B0')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else @click="toggleBox('B0')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="B1" :box="box" @click="toggleReserveBox('B1')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('B1')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="B2" :box="box" @click="toggleReserveBox('B2')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('B2')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="B3" :box="box" @click="toggleReserveBox('B3')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('B3')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="B4" :box="box" @click="toggleReserveBox('B4')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('B4')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="B5" :box="box" @click="toggleReserveBox('B5')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('B5')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="B6" :box="box" @click="toggleReserveBox('B6')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('B6')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="B7" :box="box" @click="toggleReserveBox('B7')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('B7')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="B8" :box="box" @click="toggleReserveBox('B8')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('B8')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="B9" :box="box" @click="toggleReserveBox('B9')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('B9')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
    </tr>
    <tr>
      <td></td>
      <td class="key keyright">{{ L2 }}</td>
      <td>
        <button v-if="C0" @click="toggleReserveBox('C0')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else @click="toggleBox('C0')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="C1" :box="box" @click="toggleReserveBox('C1')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('C1')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="C2" :box="box" @click="toggleReserveBox('C2')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('C2')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="C3" :box="box" @click="toggleReserveBox('C3')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('C3')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="C4" :box="box" @click="toggleReserveBox('C4')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('C4')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="C5" :box="box" @click="toggleReserveBox('C5')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('C5')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="C6" :box="box" @click="toggleReserveBox('C6')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('C6')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="C7" :box="box" @click="toggleReserveBox('C7')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('C7')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="C8" :box="box" @click="toggleReserveBox('C8')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('C8')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="C9" :box="box" @click="toggleReserveBox('C9')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('C9')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
    </tr>
    <tr>
      <td></td>
      <td class="key keyright">{{ L3 }}</td>
      <td>
        <button v-if="D0" @click="toggleReserveBox('D0')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else @click="toggleBox('D0')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="D1" :box="box" @click="toggleReserveBox('D1')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('D1')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="D2" :box="box" @click="toggleReserveBox('D2')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('D2')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="D3" :box="box" @click="toggleReserveBox('D3')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('D3')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="D4" :box="box" @click="toggleReserveBox('D4')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('D4')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="D5" :box="box" @click="toggleReserveBox('D5')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('D5')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="D6" :box="box" @click="toggleReserveBox('D6')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('D6')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="D7" :box="box" @click="toggleReserveBox('D7')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('D7')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="D8" :box="box" @click="toggleReserveBox('D8')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('D8')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="D9" :box="box" @click="toggleReserveBox('D9')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('D9')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
    </tr>
    <tr>
      <td></td>
      <td class="key keyright">{{ L4 }}</td>
      <td>
        <button v-if="E0" @click="toggleReserveBox('E0')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else @click="toggleBox('E0')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="E1" :box="box" @click="toggleReserveBox('E1')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('E1')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="E2" :box="box" @click="toggleReserveBox('E2')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('E2')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="E3" :box="box" @click="toggleReserveBox('E3')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('E3')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="E4" :box="box" @click="toggleReserveBox('E4')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('E4')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="E5" :box="box" @click="toggleReserveBox('E5')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('E5')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="E6" :box="box" @click="toggleReserveBox('E6')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('E6')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="E7" :box="box" @click="toggleReserveBox('E7')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('E7')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="E8" :box="box" @click="toggleReserveBox('E8')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('E8')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="E9" :box="box" @click="toggleReserveBox('E9')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('E9')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
    </tr>
    <tr>
      <td></td>
      <td class="key keyright">{{ L5 }}</td>
      <td>
        <button v-if="F0" @click="toggleReserveBox('F0')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else @click="toggleBox('F0')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="F1" :box="box" @click="toggleReserveBox('F1')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('F1')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="F2" :box="box" @click="toggleReserveBox('F2')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('F2')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="F3" :box="box" @click="toggleReserveBox('F3')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('F3')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="F4" :box="box" @click="toggleReserveBox('F4')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('F4')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="F5" :box="box" @click="toggleReserveBox('F5')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('F5')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="F6" :box="box" @click="toggleReserveBox('F6')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('F6')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="F7" :box="box" @click="toggleReserveBox('F7')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('F7')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="F8" :box="box" @click="toggleReserveBox('F8')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('F8')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="F9" :box="box" @click="toggleReserveBox('F9')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('F9')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
    </tr>
    <tr>
      <td></td>
      <td class="key keyright">{{ L6 }}</td>
      <td>
        <button v-if="G0" @click="toggleReserveBox('G0')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else @click="toggleBox('G0')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="G1" :box="box" @click="toggleReserveBox('G1')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('G1')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="G2" :box="box" @click="toggleReserveBox('G2')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('G2')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="G3" :box="box" @click="toggleReserveBox('G3')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('G3')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="G4" :box="box" @click="toggleReserveBox('G4')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('G4')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="G5" :box="box" @click="toggleReserveBox('G5')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('G5')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="G6" :box="box" @click="toggleReserveBox('G6')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('G6')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="G7" :box="box" @click="toggleReserveBox('G7')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('G7')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="G8" :box="box" @click="toggleReserveBox('G8')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('G8')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="G9" :box="box" @click="toggleReserveBox('G9')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('G9')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
    </tr>
    <tr>
      <td></td>
      <td class="key keyright">{{ L7 }}</td>
      <td>
        <button v-if="H0" @click="toggleReserveBox('H0')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else @click="toggleBox('H0')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="H1" :box="box" @click="toggleReserveBox('H1')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('H1')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="H2" :box="box" @click="toggleReserveBox('H2')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('H2')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="H3" :box="box" @click="toggleReserveBox('H3')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('H3')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="H4" :box="box" @click="toggleReserveBox('H4')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('H4')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="H5" :box="box" @click="toggleReserveBox('H5')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('H5')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="H6" :box="box" @click="toggleReserveBox('H6')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('H6')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="H7" :box="box" @click="toggleReserveBox('H7')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('H7')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="H8" :box="box" @click="toggleReserveBox('H8')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('H8')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="D9" :box="box" @click="toggleReserveBox('D9')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('D9')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
    </tr>
    <tr>
      <td></td>
      <td class="key keyright">{{ L8 }}</td>
      <td>
        <button v-if="I0" @click="toggleReserveBox('I0')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else @click="toggleBox('I0')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="I1" :box="box" @click="toggleReserveBox('I1')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('I1')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="I2" :box="box" @click="toggleReserveBox('I2')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('I2')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="I3" :box="box" @click="toggleReserveBox('I3')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('I3')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="I4" :box="box" @click="toggleReserveBox('I4')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('I4')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="I5" :box="box" @click="toggleReserveBox('I5')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('I5')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="I6" :box="box" @click="toggleReserveBox('I6')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('I6')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="I7" :box="box" @click="toggleReserveBox('I7')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('I7')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="I8" :box="box" @click="toggleReserveBox('I8')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('I8')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="I9" :box="box" @click="toggleReserveBox('I9')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('I9')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
    </tr>
    <tr>
      <td></td>
      <td class="key keyright">{{ L9 }}</td>
      <td>
        <button v-if="J0" @click="toggleReserveBox('J0')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else @click="toggleBox('J0')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="J1" :box="box" @click="toggleReserveBox('J1')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('J1')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="J2" :box="box" @click="toggleReserveBox('J2')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('J2')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="J3" :box="box" @click="toggleReserveBox('J3')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('J3')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="J4" :box="box" @click="toggleReserveBox('J4')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('J4')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="J5" :box="box" @click="toggleReserveBox('J5')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('J5')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="J6" :box="box" @click="toggleReserveBox('J6')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('J6')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="J7" :box="box" @click="toggleReserveBox('J7')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('J7')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="J8" :box="box" @click="toggleReserveBox('J8')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('J8')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
      <td>
        <button v-if="J9" :box="box" @click="toggleReserveBox('J9')">
          <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </button>
        <button v-else :box="box" @click="toggleBox('J9')">
          <img alt="Vue logo" src="../assets/icons8-lock-48.png" />
        </button>
      </td>
    </tr>
     </tbody>
  </table>

  <div class="divider">
    <div>
      <label>Pool Manager:</label>
    </div>
    <div>
      <label class="manager"
        >{{ poolData.firstName }} {{ poolData.lastName }}</label
      >
      |
      <a href="#" id="creatorPhone" @click="setCreatorPhone()">{{
        poolData.phone
      }}</a>
      |
      <a href="#" id="creatorEmail" @click="setCreatorEmail()">{{
        poolData.email
      }}</a>
    </div>
    <p>{{ poolData.poolDescription }}</p>
  </div>
  <div class="divider">
    <!-- <a href="">Invite Friends</a> -->
    <a
      @click="setInvitePhone()"
      id="invitePhone"
      href="sms:?&body=Join%20My%20Box%20Pool%20boxpools.app/pool/604a4ba4b556884bc0ca4b42"
      >Invite Friends (Text)</a
    >
    |
    <a
      @click="setInviteEmail()"
      id="inviteEmail"
      href="mailto:?subject=Join My Box Pool&body=boxpools.app/pool/604a4ba4b556884bc0ca4b42"
      >Invite Friends (Email)</a
    >
  </div>
  <div>
    <a href="#">Pool Payment Options</a>
  </div>
</template>

<script>
import Box from '../components/Box';
import ReserveBox from '../components/ReserveBox';
export default {
  mounted() {
    this.fetchPool();
  },
  components: { Box, ReserveBox },
  data() {
    return {
      poolData: {},
      box: '',
      showBox: false,
      showReserveBox: false,
      modalFirstName: '',
      modalLastName: '',
      modalEmail: '',
      modalPhone: '',
      modalPaid: false,
      A0: false,
      A1: false,
      A2: false,
      A3: false,
      A4: false,
      A5: false,
      A6: false,
      A7: false,
      A8: false,
      A9: false,
      B0: false,
      B1: false,
      B2: false,
      B3: false,
      B4: false,
      B5: false,
      B6: false,
      B7: false,
      B8: false,
      B9: false,
      C0: false,
      C1: false,
      C2: false,
      C3: false,
      C4: false,
      C5: false,
      C6: false,
      C7: false,
      C8: false,
      C9: false,
      D0: false,
      D1: false,
      D2: false,
      D3: false,
      D4: false,
      D5: false,
      D6: false,
      D7: false,
      D8: false,
      D9: false,
      E0: false,
      E1: false,
      E2: false,
      E3: false,
      E4: false,
      E5: false,
      E6: false,
      E7: false,
      E8: false,
      E9: false,
      F0: false,
      F1: false,
      F2: false,
      F3: false,
      F4: false,
      F5: false,
      F6: false,
      F7: false,
      F8: false,
      F9: false,
      G0: false,
      G1: false,
      G2: false,
      G3: false,
      G4: false,
      G5: false,
      G6: false,
      G7: false,
      G8: false,
      G9: false,
      H0: false,
      H1: false,
      H2: false,
      H3: false,
      H4: false,
      H5: false,
      H6: false,
      H7: false,
      H8: false,
      H9: false,
      I0: false,
      I1: false,
      I2: false,
      I3: false,
      I4: false,
      I5: false,
      I6: false,
      I7: false,
      I8: false,
      I9: false,
      J0: false,
      J1: false,
      J2: false,
      J3: false,
      J4: false,
      J5: false,
      J6: false,
      J7: false,
      J8: false,
      J9: false,
      poolLink: `http://boxpools.app/pool/${this.$route.params.id}`,
      poolId: '',
      isGenerated: false,
      K0: '?',
      K1: '?',
      K2: '?',
      K3: '?',
      K4: '?',
      K5: '?',
      K6: '?',
      K7: '?',
      K8: '?',
      K9: '?',
      L0: '?',
      L1: '?',
      L2: '?',
      L3: '?',
      L4: '?',
      L5: '?',
      L6: '?',
      L7: '?',
      L8: '?',
      L9: '?'
    };
  },
  methods: {
    toggleBox(box) {
      this.showBox = !this.showBox;
      this.box = box;
      this.setBoxData(box)
    },
    toggleBoxDefault() {
      this.showBox = !this.showBox;
    },
    setBoxData(box) {
      const index = this.poolData.boxes.findIndex((i) => i.location === box);
      this.modalFirstName = this.poolData.boxes[index].firstName;
      this.modalLastName = this.poolData.boxes[index].lastName;
      this.modalEmail = this.poolData.boxes[index].email;
      this.modalPhone = this.poolData.boxes[index].phone;
      this.modalPaid = this.poolData.boxes[index].paid;
    },
    toggleReserveBox(box) {
      this.showReserveBox = !this.showReserveBox;
      this.box = box;
      // this.poolId = poolId;
    },
    toggleReserveBoxDefault() {
      this.showReserveBox = !this.showReserveBox;
    },
    fetchPool() {
      fetch(`${process.env.VUE_APP_BASE_URL}/pools/${this.$route.params.id}`)
        .then((response) => response.json())
        .then((data) => {
          if (!data._id) {
            this.$toast.error(data.message, {
              position: 'top',
              duration: 5000
            });
            this.$router.push({ name: 'View' });
          }
          this.poolData = data;
          this.A0 = data.boxes[0].available;
          this.A1 = data.boxes[1].available;
          this.A2 = data.boxes[2].available;
          this.A3 = data.boxes[3].available;
          this.A4 = data.boxes[4].available;
          this.A5 = data.boxes[5].available;
          this.A6 = data.boxes[6].available;
          this.A7 = data.boxes[7].available;
          this.A8 = data.boxes[8].available;
          this.A9 = data.boxes[9].available;
          this.B0 = data.boxes[10].available;
          this.B1 = data.boxes[11].available;
          this.B2 = data.boxes[12].available;
          this.B3 = data.boxes[13].available;
          this.B4 = data.boxes[14].available;
          this.B5 = data.boxes[15].available;
          this.B6 = data.boxes[16].available;
          this.B7 = data.boxes[17].available;
          this.B8 = data.boxes[18].available;
          this.B9 = data.boxes[19].available;
          this.C0 = data.boxes[20].available;
          this.C1 = data.boxes[21].available;
          this.C2 = data.boxes[22].available;
          this.C3 = data.boxes[23].available;
          this.C4 = data.boxes[24].available;
          this.C5 = data.boxes[25].available;
          this.C6 = data.boxes[26].available;
          this.C7 = data.boxes[27].available;
          this.C8 = data.boxes[28].available;
          this.C9 = data.boxes[29].available;
          this.D0 = data.boxes[30].available;
          this.D1 = data.boxes[31].available;
          this.D2 = data.boxes[32].available;
          this.D3 = data.boxes[33].available;
          this.D4 = data.boxes[34].available;
          this.D5 = data.boxes[35].available;
          this.D6 = data.boxes[36].available;
          this.D7 = data.boxes[37].available;
          this.D8 = data.boxes[38].available;
          this.D9 = data.boxes[39].available;
          this.E0 = data.boxes[40].available;
          this.E1 = data.boxes[41].available;
          this.E2 = data.boxes[42].available;
          this.E3 = data.boxes[43].available;
          this.E4 = data.boxes[44].available;
          this.E5 = data.boxes[45].available;
          this.E6 = data.boxes[46].available;
          this.E7 = data.boxes[47].available;
          this.E8 = data.boxes[48].available;
          this.E9 = data.boxes[49].available;
          this.F0 = data.boxes[50].available;
          this.F1 = data.boxes[51].available;
          this.F2 = data.boxes[52].available;
          this.F3 = data.boxes[53].available;
          this.F4 = data.boxes[54].available;
          this.F5 = data.boxes[55].available;
          this.F6 = data.boxes[56].available;
          this.F7 = data.boxes[57].available;
          this.F8 = data.boxes[58].available;
          this.F9 = data.boxes[59].available;
          this.G0 = data.boxes[60].available;
          this.G1 = data.boxes[61].available;
          this.G2 = data.boxes[62].available;
          this.G3 = data.boxes[63].available;
          this.G4 = data.boxes[64].available;
          this.G5 = data.boxes[65].available;
          this.G6 = data.boxes[66].available;
          this.G7 = data.boxes[67].available;
          this.G8 = data.boxes[68].available;
          this.G9 = data.boxes[69].available;
          this.H0 = data.boxes[70].available;
          this.H1 = data.boxes[71].available;
          this.H2 = data.boxes[72].available;
          this.H3 = data.boxes[73].available;
          this.H4 = data.boxes[74].available;
          this.H5 = data.boxes[75].available;
          this.H6 = data.boxes[76].available;
          this.H7 = data.boxes[77].available;
          this.H8 = data.boxes[78].available;
          this.H9 = data.boxes[79].available;
          this.I0 = data.boxes[80].available;
          this.I1 = data.boxes[81].available;
          this.I2 = data.boxes[82].available;
          this.I3 = data.boxes[83].available;
          this.I4 = data.boxes[84].available;
          this.I5 = data.boxes[85].available;
          this.I6 = data.boxes[86].available;
          this.I7 = data.boxes[87].available;
          this.I8 = data.boxes[88].available;
          this.I9 = data.boxes[89].available;
          this.J0 = data.boxes[90].available;
          this.J1 = data.boxes[91].available;
          this.J2 = data.boxes[92].available;
          this.J3 = data.boxes[93].available;
          this.J4 = data.boxes[94].available;
          this.J5 = data.boxes[95].available;
          this.J6 = data.boxes[96].available;
          this.J7 = data.boxes[97].available;
          this.J8 = data.boxes[98].available;
          this.J9 = data.boxes[99].available;
          this.poolId = data._id;
          this.isGenerated = data.isGenerated;
          if (data.isGenerated) {
            this.K0 = data.boxKeys[0].value;
            this.K1 = data.boxKeys[1].value;
            this.K2 = data.boxKeys[2].value;
            this.K3 = data.boxKeys[3].value;
            this.K4 = data.boxKeys[4].value;
            this.K5 = data.boxKeys[5].value;
            this.K6 = data.boxKeys[6].value;
            this.K7 = data.boxKeys[7].value;
            this.K8 = data.boxKeys[8].value;
            this.K9 = data.boxKeys[9].value;
            this.L0 = data.boxKeys[10].value;
            this.L1 = data.boxKeys[11].value;
            this.L2 = data.boxKeys[12].value;
            this.L3 = data.boxKeys[13].value;
            this.L4 = data.boxKeys[14].value;
            this.L5 = data.boxKeys[15].value;
            this.L6 = data.boxKeys[16].value;
            this.L7 = data.boxKeys[17].value;
            this.L8 = data.boxKeys[18].value;
            this.L9 = data.boxKeys[19].value;
          }
        });
    },
    setCreatorPhone() {
      document.getElementById(
        'creatorPhone'
      ).href = `sms:${this.poolData.phone}`;
    },
    setCreatorEmail() {
      document.getElementById(
        'creatorEmail'
      ).href = `mailto:${this.poolData.email}`;
    },
    setInvitePhone() {
      document.getElementById(
        'invitePhone'
      ).href = `sms:?&body=Join%20My%20Box%20Pool%20${this.poolLink}`;
    },
    setInviteEmail() {
      document.getElementById(
        'inviteEmail'
      ).href = `mailto:?subject=Join My Box Pool&body=${this.poolLink}`;
    }
  }
};
</script>
<style scoped>
.key {
  font-weight: bold;
  color: white;
}
.keyright {
  padding-right: 10px;
}
button {
  background: white;
  /* border: 0;
  padding: 10px 20px;
  margin-top: 20px;
  color: white;
  border-radius: 20px; */
}
img {
  max-width: 48px;
  max-height: 48px;
}

.caption {
  padding-bottom: 30px;
  font-weight: bold;
  color: #b5b7b9;
}
.leftcaption {
  writing-mode: vertical-rl;
  text-orientation:upright;
  font-weight: bold;
  padding-right: 15px;
  padding-left: 10px;
  color: #b5b7b9;
}
.divider {
  color: #b5b7b9;
}
.manager {
  color: #61c1ee;
}
p {
  display:inline-block;
  max-width: 400px;
  text-align: center;
}
</style>
