<template>
  <div>
    <h1>Forgot Password</h1>
  </div>
  <form @submit.prevent="handleSubmit()">
    <input type="email" required v-model="email" placeholder="Email" />
    <div class="submit">
      <button>Submit</button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      emailFound: true
    };
  },
  methods: {
    handleSubmit() {
      if (this.emailFound) {
        this.$router.push({ name: 'Reset' });
      } else {
        this.$router.push({ name: 'Login' });
      }
    }
  }
};
</script>

<style></style>
