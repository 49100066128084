<template>
    <div>
        <h1>FAQ</h1>
        <h2>How Do Box Pools Work?</h2>
        <p>There are 100 boxes or squares to choose from. After all boxes are filled, the pool manager will generate random numbers for each team. Match the last digits of the score for each team to win. We provide the service for a fun online version of a traditional box pool, but we do not handle pool payments. We still leave that up to the pool manager.</p>
        <h2>Managers</h2>
        <p>Only managers are able to generate the random numbers. Managers can reset a box to remove a person. Managers also can mark a person as paid.</p>
        <h2>Pricing</h2>
        <p>Free while in Beta!!!</p>
        <p>Pricing will be $5 a pool once we officially launch.</p>
        <p>We are planning to accept credit cards and cryptocurrency once we come out of beta.</p>
        <p>Other similar services charge $10-$20 + for a pool. That is just a rip off. Go with us. We are cheaper and more fun.</p>
        <h2>BETA</h2>
        <p>While we are in BETA, customers might experience bugs. We will make every effort to fix these in a timely manner. If you are experiencing any bugs, please email our support team at boxpoolsapp@gmail.com.</p>
        <p>We plan on using this beta period to gather feedback on our application. We are planning to add the ability to have your own account, see your managed and joined pools, and other cool features that will make it easier to manage your box pools in the near future. Any feature request or feeback, please email us at boxpoolsapp@gmail.com and let us know what you like and what you don't.</p>
        <h2>Apps</h2>
        <p>Our application is considered a web application. You can access it from any modern web browser. Please don't use Internet Explorer. It's garbage. We recommend using chrome or brave browser. We do not offer an android or ios native app and will never offer those. Save our website to your home screen if you want a quick way to access the site.</p>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
p {
    font-style: normal;
}
h2 {
    color: #61c1ee;
}
</style>