<template>
  <div class="backdrop" @click.self="closeBox()">
    <div class="box">
      <form @submit.prevent="handleSubmit()">
        <h1>{{ box }}</h1>
        <input required v-model="firstName" placeholder="First Name" />
        <input required v-model="lastName" placeholder="Last Name" />
        <input
          type="email"
          required
          v-model="email"
          placeholder="username@email.com"
        />
        <input
          type="phone"
          required
          v-model="phone"
          placeholder="555-888-77777"
        />
        <div>
          <label>Paid</label>
          <input type="checkbox" v-model="paid" />
        </div>

        <div class="submit">
          <button>Update</button>
        </div>
      </form>
      <div>
        <button @click="resetBox()">Reset Box</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Box',
  props: ['poolId', 'box', 'firstName', 'lastName', 'email', 'phone', 'paid'],
  data() {
    return {};
  },
  methods: {
    closeBox() {
      this.$emit('close');
    },
    resetBox() {
      const reset = confirm(
        'Are you sure you want to reset this box? All data will be permanently deleted.'
      );
      if (reset) {
        const body = {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          available: true,
          paid: false
        };
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        };
        fetch(
          `${process.env.VUE_APP_BASE_URL}/pools/${this.poolId}/box/${this.box}`,
          requestOptions
        ).then(async (response) => {
          const data = await response.json();
          // console.log(data);
          if (response.status !== 200) {
            this.$toast.error(data.message, {
              position: 'top',
              duration: 5000
            });
          } else {
             this.closeBox();
            this.$toast.success('Box Reset!', {
              position: 'top',
              duration: 5000
            });
            this.$router.go(0);
          }
        });
      }
    },
    handleSubmit() {
      const body = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
        available: false,
        paid: this.paid
      };
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      };
      fetch(
        `${process.env.VUE_APP_BASE_URL}/pools/${this.poolId}/box/${this.box}`,
        requestOptions
      ).then(async (response) => {
        const data = await response.json();
        // console.log(data);
        if (response.status !== 200) {
          this.$toast.error(data.message, {
            position: 'top',
            duration: 5000
          });
        } else {
          this.closeBox();
          this.$toast.success('Box Updated!', {
            position: 'top',
            duration: 5000
          });
          this.$router.go(0);
        }
      });
    }
  }
};
</script>

<style scoped>
.box {
  width: 400px auto;
  padding: 20px;
  margin: 100px auto;
  background: white;
  border-radius: 10px;
}
.backdrop {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
h1 {
  color: #61c1ee;
  border: none;
  padding: 0;
  text-align: center;
}
.box p {
  font-style: normal;
}
input[type='checkbox'] {
  display: inline-block;
  width: 100%;
}
label {
  display: inline-block;
  margin: auto;
  text-align: center;
}
</style>
