<template>
  <div>
    <h1>Login</h1>
  </div>
  <form @submit.prevent="handleSubmit()">
      <input type="email" required v-model="email" placeholder="Email"/>
      <input
      type="password"
      required
      v-model="password"
      placeholder="Password"
    />
    <div class="submit">
      <button>Log In</button>
    </div>
    <br>
    <br>
    <div class="submit">
        <router-link :to="{ path: 'Forgot' }">Forgot Password ?</router-link>
    </div>
  </form>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        handleSubmit() {
            localStorage.bpa_token = 'themostsecuretoken';
            this.$router.push({name: 'UserPools'})
        }
    }
}
</script>

<style>

</style>