<template>
  <div>
    <h1>My Pools</h1>
  </div>
  <div>
    <button @click="createPool()">Create Pool</button>
  </div>
  <div v-if="loading">
      <Loading/>
  </div>
  <div v-else v-for="item in pools" v-bind:key="item.id">
    <UserPool
      :poolName="item.poolName"
      :poolDate="item.poolDate"
      :poolCost="item.poolCost"
      :poolManager="item.poolManager"
      :poolInactive="item.isInactive"
    />
  </div>
</template>

<script>
import UserPool from '../components/UserPool.vue';
import Loading from '../components/Loading.vue';
export default {
  components: { UserPool, Loading },
  data() {
    return {
      pools: [],
      loading: false
    };
  },
  mounted() {
      this.getUserData();
  },
  methods: {
      createPool() {
          this.$router.push({ name: 'Create' });
      },
    // get all users pools and then set the pools
    getUserData() {
        const pools = [
        {
          id: '12345',
          poolName: 'Dans Most Awesome Pool 2021',
          poolDate: 'March 18, 2021',
          poolCost: '25',
          poolManager: false,
          isInactive: true
        },
        {
          id: '6890',
          poolName: 'Dans Gay Awesome Pool 2021',
          poolDate: 'February 04, 2021',
          poolCost: '50',
          poolManager: true,
          isInactive: false
        }
      ]
      this.pools = pools;
    }
  }
};
</script>

<style></style>
