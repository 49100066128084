<template>
  <div class="backdrop" @click.self="closeBox()">
    <div class="box">
      <form @submit.prevent="handleSubmit()">
        <h1>{{ box }}</h1>
        <input required v-model="firstName" placeholder="First Name" />
        <input required v-model="lastName" placeholder="Last Name" />
        <input
          type="email"
          required
          v-model="email"
          placeholder="username@email.com"
        />
        <input
          type="phone"
          required
          v-model="phone"
          placeholder="555-888-77777"
        />
        <div class="submit">
          <button>Reserve Box</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReserveBox',
  props: ['box', 'poolId'],
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    };
  },
  methods: {
    closeBox() {
      this.$emit('close');
    },
    handleSubmit() {
      // console.log('testing');
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          available: false,
          paid: false
        })
      };
      fetch(
        `${process.env.VUE_APP_BASE_URL}/pools/${this.poolId}/box/${this.box}`,
        requestOptions
      ).then(async (response) => {
        const data = await response.json();
        // console.log(data);
        if (response.status !== 200) {
          this.$toast.error(data.message, {
            position: 'top',
            duration: 5000
          });
        } else {
          this.closeBox();
          this.$toast.success('Box Reserved!', {
            position: 'top',
            duration: 5000
          });
          this.$router.go(0);
        }
      });
    }
  }
};
</script>

<style scoped>
.box {
  width: 400px auto;
  padding: 20px;
  margin: 100px auto;
  background: white;
  border-radius: 10px;
}
.backdrop {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
h1 {
  color: #61c1ee;
  border: none;
  padding: 0;
  text-align: center;
}
.box p {
  font-style: normal;
}
</style>
