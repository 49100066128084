<template>
  <div>
    <h1>Reset Password</h1>
  </div>
  <form @submit.prevent="handleSubmit()">
    <input
      type="password"
      required
      v-model="password1"
      placeholder="password"
    />
    <input
      type="password"
      required
      v-model="password2"
      placeholder="retype password"
    />

    <div class="submit">
      <button>Submit</button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      password1: '',
      password2: ''
    };
  },
  methods: {
    handleSubmit() {
      if (this.password1 === this.password2) {
        this.$router.push({ name: 'Login' });
        this.$toast.success('Password Updated Successfully!', {
          position: 'top',
          duration: 5000
        });
      } else {
        this.$toast.error('Passwords Do Not Match', {
          position: 'top',
          duration: 5000
        });
      }
    }
  }
};
</script>
