<template>
  <div>
    <h1>Sign Up Page</h1>
  </div>
  <form @submit.prevent="handleSubmit()">
    <input type="email" required v-model="email" placeholder="Email" />
    <input type="password" required v-model="password" placeholder="Password" />
    <div class="submit">
      <button>Sign Up</button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    handleSubmit() {
      this.$router.push({ name: 'UserPools' });
      this.$toast.success('Account Created Successfully!', {
        position: 'top',
        duration: 5000
      });
    }
  }
};
</script>

<style></style>
