<template>
  <table>
    <tbody>
      <tr>
        <!-- <td><button @click="$router.push({ name: 'Login' })">Sign In</button></td> -->
        <td>
            <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </td>
        <td>
            <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </td>
        <td>
            <img alt="Vue logo" src="../assets/icons8-padlock-48.png" />
        </td>
        <!-- <td><button @click="$router.push({ name: 'SignUp' })">Sign Up</button></td> -->
      </tr>
      <tr>
        <td><button @click="$router.push({ name: 'Create' })">Create</button></td>
        <td><button @class="btn-txt" @click="$router.push({ name: 'View' })">View</button></td>
        <td><button @click="$router.push({ name: 'Manage' })">Manage</button></td>
      </tr>
      <tr>
        <td><img alt="Vue logo" src="../assets/icons8-lock-48.png" /></td>
        <td><img alt="Vue logo" src="../assets/icons8-lock-48.png" /></td>
        <td><img alt="Vue logo" src="../assets/icons8-lock-48.png" /></td>
      </tr>
    </tbody>
  </table>

</template>

<script>
export default {
  methods: {}
};
</script>

<style scoped>
.btn-home {
  padding: 40px;
}
.btn-txt {
  font-size: 20px;
}
table,
th,
td {
   /* width:100%; */
  border: 2px solid white;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
}
th,
td {
  padding: 15px;
  font-weight: bold;
  color: white;
}
</style>
