<template>
  <footer>
    <div>
      <a href="https://boxpools.app">&copy; {{ year }} boxpools.app</a>
      <label class="new">API:{{ apiVersion }} | APP:{{ appVersion }}</label>
      <router-link :to="{ path: 'FAQ' }">FAQ</router-link>
    </div>
    <div>
      <a href="mailto:boxpoolsapp@gmail.com">Email Us!</a> |
      <a href="sms:7327051826">Text Us!</a> |
      <a href="http://facebook.com/boxpoolsapp">Facebook</a> |
      <a href="http://instagram.com/boxpoolsapp">Instagram</a> |
      <a href="http://twitter.com/boxpoolsapp">Twitter</a>
    </div>
  </footer>
</template>

<script>
import axios from 'axios';
import { version } from '../../package.json';
export default {
  name: 'Footer',
  mounted() {
    axios
      .get(`${process.env.VUE_APP_BASE_URL}/version`)
      .then((response) => {
        this.apiVersion = response.data.version;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  data() {
    return {
      year: new Date().getFullYear(),
      apiVersion: null,
      appVersion: version
    };
  }
};
</script>

<style scoped>
a {
  color: #61c1ee;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
div {
  color: #b5b7b9;
}
.new {
  display: block;
  margin: 0;
}
</style>
