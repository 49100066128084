<template>
  <div>
    <div class="btn-home">
      <button class="btn-txt" @click="$router.push({name: 'Create'})">Create</button>
    </div>
     <div class="btn-home">
      <button class="btn-txt" @click="$router.push({name: 'View'})">View</button>
    </div>
     <div class="btn-home">
      <button class="btn-txt" @click="$router.push({name: 'Manage'})">Manage</button>
    </div>
  </div>
</template>

<script>

export default {
  methods:{
  }
}
</script>

<style>
  .btn-home {
    padding: 40px;
  }
  .btn-txt {
     font-size: 20px;
  }
</style>