<template>
    <div class="backdrop" @click.self="closeBox()">
        <div class="box">
            <h1>{{ box }}</h1>
            <p>{{ firstName }}</p>
            <p>{{ lastName }}</p>
            <p>{{ email }}</p>
            <p>{{ phone }}</p>
            <label>Paid</label>
            <input type="checkbox" v-model="paid" disabled="disabled">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Box',
    props: ['box', 'firstName', 'lastName', 'email', 'phone', 'paid'],
    methods: {
        closeBox(){
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
    .box {
        width: 400px auto;
        padding: 20px;
        margin: 100px auto;
        background: white;
        border-radius: 10px;
    }
    .backdrop {
        top: 0;
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
    }
    h1 {
        color: #61c1ee;
        border: none;
        padding: 0;
    }
    .box p {
        font-style: normal;
    }
</style>