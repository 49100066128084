<template>
  <div>
    <h1>Create Pool</h1>
  </div>
  <div v-if="showModal">
    <Modal :header="modalHeader" :text="modalText" @close="toggleModal()" />
  </div>
  <form @submit.prevent="handleSubmit()">
    <input required v-model="firstName" placeholder="First Name" />
    <input required v-model="lastName" placeholder="Last Name" />
    <input
      type="email"
      required
      v-model="email"
      placeholder="username@email.com"
    />
    <input type="phone" required v-model="phone" placeholder="555-888-77777" />
    <input required v-model="poolName" placeholder="Fun Pool Name" />
    <textarea required  v-model="poolDescription" placeholder="Description of how your pool works"></textarea>
    <input required v-model="homeTeam" placeholder="Home Team" />
    <input required v-model="awayTeam" placeholder="Away Team" />
    <input required v-model="cost" placeholder="Cost Per Box" />
    <input
      type="password"
      required
      v-model="poolPassword"
      placeholder="Pool Password"
    />
    <div v-if="poolPasswordError" class="error">{{ poolPasswordError }}</div>
    <input
      type="password"
      required
      v-model="managerPassword"
      placeholder="Manager Password"
    />
    <div v-if="managerPasswordError" class="error">
      {{ managerPasswordError }}
    </div>
    <div class="label">
      <label>Event Date</label>
      <input
        type="date"
        required
        v-model="eventDate"
        placeholder="MM/DD/YYYY"
      />
    </div>

    <div class="terms">
      <input type="checkbox" required v-model="terms" />
      <a href="#" @click="toggleModal()">Accept Terms of Service</a>
    </div>

    <div class="submit">
      <button>Create Pool</button>
    </div>
  </form>
</template>

<script>
import Modal from '../components/Modal';
export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      poolName: '',
      poolDescription: '',
      homeTeam: '',
      awayTeam: '',
      cost: '',
      poolPassword: '',
      managerPassword: '',
      eventDate: '',
      terms: false,
      poolPasswordError: '',
      managerPasswordError: '',
      showModal: false,
      modalHeader: 'Terms and Agreements',
      modalText: 'These are the terms and agreements'
    };
  },
  components: { Modal },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    handleSubmit() {
      // validate pw
      this.poolPasswordError =
        this.poolPassword.length > 5
          ? ''
          : 'Pool Password must be at least 6 characters long';

      this.managerPasswordError =
        this.managerPassword.length > 5
          ? ''
          : 'Manager Password must be at least 6 characters long';
      if (!this.managerPasswordError && !this.poolPasswordError && this.terms) {
        // send api request here
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            poolName: this.poolName,
            poolDescription: this.poolDescription,
            homeTeam: this.homeTeam,
            awayTeam: this.awayTeam,
            poolPassword: this.poolPassword,
            managerPassword: this.managerPassword,
            cost: this.cost,
            eventDate: this.eventDate,
            boxes: [],
            boxKeys: [],
            isActive: true,
            isGenerated: false,
            paymentMethods: [
              {
                type: 'paypal',
                value: 'djreale@gmail.com'
              },
              {
                type: 'zelle',
                value: 'djreale@gmail.com'
              }
            ]
          })
        };
        fetch(`${process.env.VUE_APP_BASE_URL}/pools`, requestOptions).then(
          async (response) => {
            const data = await response.json();
            // console.log(data);
            if (response.status !== 200) {
              this.$toast.error('Pool NOT Created!', {
                position: 'top',
                duration: 5000
              });
            } else {
              // this.$toast.success(
              //   'Pool Created! Please Check your Email for your Pool Id',
              //   {
              //     position: 'top',
              //     duration: 5000
              //   }
              // );
              // send email
              const requestOptionsEmail = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  firstName: this.firstName,
                  poolId: data.poolId,
                  email: this.email,
                  poolPassword: this.poolPassword,
                  managerPassword: this.managerPassword
                })
              };
              fetch(
                `${process.env.VUE_APP_BASE_URL}/email`,
                requestOptionsEmail
              );
               this.$router.push({name: 'Manage'});
               this.$toast.success(
                'Pool Created! Please Check your Email for your Pool Id.',
                {
                  position: 'top',
                  duration: 5000
                }
              );
            }
          }
        );
      }
    }
  }
};
</script>
<style scoped>
.terms {
  text-align: center;
}
.label {
  text-align: center;
}
</style>
